/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (Swiper) => {

        var mySwiper = new Swiper('.swiper-container.swiperlogo', {
            slidesPerView: 5,
            centeredSlides: true,
            speed: 400,
            spaceBetween: 100,
            autoplay: {
                delay: 3000
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                992: {
                    centeredSlides: true,
                    slidesPerView: 3,
                    spaceBetween: 10,
                    allowSlidePrev: true,
                    allowSlideNext: true
                },
                765: {
                    centeredSlides: true,
                    slidesPerView: 1,
                    spaceBetween: 10,
                    allowSlidePrev: true,
                    allowSlideNext: true,
                },
            }
        });

        var mySwiper = new Swiper('.swiper-container.swiperimg', {
            speed: 400,
            spaceBetween: 100,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },

        });
    }
}

