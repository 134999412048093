/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (Swiper) => {

        let btnNext = document.querySelector(".services .btn-next")
        let btnPrevious = document.querySelector(".services .btn-previous")

        const swiperOptions = {
            spaceBetween: 30,
            slidesPerView: "auto",
            speed: 600,
            navigation: {
                prevEl: btnPrevious,
                nextEl: btnNext
            },
        };
        const swiper = new Swiper(document.querySelector(".slider-services"), swiperOptions);
    }
}

